<template lang="pug">
	content-page.frontpage
		div(slot="heading")
			v-row(no-gutters)
				v-col(cols="12" sm="4" v-for="{id, title, item, path, type} in mappedFrontpageItems.slice(0,3)" :key="id")
					v-img.pa-6.pa-sm-3.pa-md-6(:aspect-ratio="16/9" :src="`${item.photo}?width=800`" :srcset="`${item.photo}?width=400 420w, ${item.photo}?width=640 640w, ${item.photo}?width=800 800w `" sizes="(max-width: 1260px) 420px, (max-width: 1920px) 640px, 800px"  :alt="title" gradient="180deg,transparent 0,#212121" )
						.d-flex.flex-column-reverse(style="height:100%;min-height:164px")
							template(v-if="type === 'area'")
								v-row.mt-2.white--text(no-gutters)
									v-col
										v-chip.mr-4.white--text(small color="blue lighten-2") {{$t('frontpage.chip.piste.km_{count}', {count: item.slopeinfo.total})}}
										v-chip.white--text(small color="blue lighten-2") {{$t('frontpage.chip.elevation.m_{valley}_{mountain}', {valley: item.minElevation, mountain: item.maxElevation})}}
								optional-nuxt-link.header-link__fill.d-flex.grey--text(:to="localePath({...path})")
									.text-h6.text-sm-body-1.text-md-h5.text-lg-h4.font-weight-bold.white--text
										img.mr-4.mt-2.frontpage-flag(:src="`https://static.wintersport.nl/images/flags/svg/${item.country.countryCode}.svg`" :alt="item.country.name" width="36")
										| {{title}}
							template(v-else-if="type === 'custom'")
								a.header-link__fill.d-flex.grey--text(:href="item.url" target="_blank")
									.text-h6.text-sm-body-1.text-md-h5.text-lg-h4.font-weight-bold.white--text {{title}}
							template(v-else)
								v-row.white--text.shrink(no-gutters)
									v-col.mr-1.shrink.text-no-wrap
										nuxt-link(v-if="item.author && item.category !== 'advertorial'" :to="localePath({name:'profile-slug', params: {slug: item.author.slug}})" :title="item.author.username") {{item.author.username}}
										v-chip.px-1.mr-2.flex-grow-0.flex-shrink-0(label x-small color="grey lighten-2" v-if="item.category == 'advertorial'") gesponsord
									v-col.mr-1.shrink.text-no-wrap(v-if="item.displayDate") {{$t('weblog-item:published.at.{date}', {date: $dayjs(item.displayDate).format($vuetify.breakpoint.mdAndDown ? 'DD-MM-YYYY' : 'DD MMMM YYYY')})}}
									v-col.mr-1.shrink.text-no-wrap(v-if="item.replyCount")
										optional-nuxt-link.grey--text(:to="localePath({...path, hash: '#reacties'})") {{$tc('page.weblog.message:reply.{count}', item.replyCount, {count: item.replyCount})}}
								optional-nuxt-link.header-link__fill.d-flex.grey--text(:to="localePath({...path})")
									.text-h6.text-sm-body-1.text-md-h5.text-lg-h4.font-weight-bold.white--text {{title}}
									v-chip.px-1.flex-grow-0.flex-shrink-0.ml-3.mb-2(label x-small color="grey lighten-2" v-if="type === 'academylesson'") academy

		div(slot="nav")
			navigation-default
		.flow-left
			h2 Weitere Highlights
			v-data-iterator.mb-2(:items="mappedFrontpageItems.slice(3)" hide-default-footer)
				template(v-slot:item="{ item }")
					template(v-if="item.type === 'custom' || item.type === 'contentpage'")
						v-row
							v-col.shrink(cols="12" md="auto")
								a(:href="item.url" target="_blank")
									v-img(:aspect-ratio="16/9" :width="$vuetify.breakpoint.smAndUp ? 180: 'auto'" min-width="180" :src="`${item.photo}`" :alt="item.title")
							v-col.grow
								a.d-block.font-weight-bold.subtitle-1(:href="item.url" target="_blank") {{item.title}}
								p.text--primary {{item.content}}

					weblog-item(v-else :weblog="{...item.item, title: item.title}" :override-path="item.path")

		template(v-if="area")
			h3.mt-4 Wettervorhersage
			span.mb-4
				nuxt-link.h2(:to="destinationLink" :title="area.name") 6-Tage Wettervorhersage {{activeDestination.name}}
				a.ml-1(@click="destinationForm = true") {{$t('forecast.sidebar.label.change-destination')}}

			validation-observer(v-slot="{ handleSubmit, invalid}" v-if="destinationForm")
				form.my-6(@submit.prevent="handleSubmit(changeDestination)")
					validation-provider(v-slot="{errors, touched}" rules="required" :name="$t('forecast.sidebar:edit.destination.dialog.destination.input')" slim)
						destination-selector.mb-3(outlined :label="$t('forecast.sidebar:edit.destination.dialog.destination.input')" v-model="newDestination" hide-details="auto" :error-messages="touched ? errors : []")
					v-spacer
					v-btn.mr-4(outlined color="primary" @click="destinationForm = false;") {{$t('forecast.sidebar:edit.destination.dialog.cancel')}}
					v-btn(color="primary" depressed type="submit" :disabled="invalid") {{$t('forecast.sidebar:edit.destination.dialog.submit')}}

			div.mb-2.font-weight-bold
				span.mr-2 SCHNEEHÖHEN TAL
				span.mr-4.blue--text {{maxSnowValley}}cm
				span.mr-2 BERG
				span.mr-4.blue--text {{maxSnowMountain}}cm

			.forecast-table-wrapper
				table.forecast-table.mb-6(ref="table")
					colgroup
						col(width="100px")
						col( v-for="forecast in area.forecasts_daily" :style="`background-color:${[0,6].includes($dayjs(forecast.realdate).day())? '#fafafa' : '#fff'}`" span="8")
					thead
						tr
							td.label Tag
							th(colspan="8" v-for="forecast in area.forecasts_daily") {{$dayjs(forecast.realdate).format('dd')}}
					tbody
						tr
							td.label Wetter
							td(:colspan="8/blocksPerDay" v-for="forecast in area.forecasts")
								img(:src="`https://static.wintersport.nl/images/weather/${forecast.icon}.svg`" width="16px" loading="lazy")
						tr
							td.label Temp
							display-temp(tag="td" :colspan="8/blocksPerDay" v-for="forecast in area.forecasts" :key="`temp-${forecast.hour}`") {{forecast.temperature}}
						tr(v-if="area.resorts && area.resorts.nodes.length").bottom-border.compact
							th {{$t('page.areas._area.forecast:forecast_table.zeroline')}}
							td(v-for="(forecast, index) in area.zeroline")
								zeroline( v-if="index===0" :data="area.zeroline" :width="tableContentWidth"  :mountain="area.maxElevation" :valley="area.resorts.nodes[0].elevation" :min="area.resorts.nodes[0].elevation" )
						tr.bottom-border
							th
								span {{$t('page.areas._area.forecast:forecast_table.snowfall')}}
							td.bar-container.bg-lightblue(v-for="forecast in barforecasts" style="height:100px" :colspan="8/blocksPerDay" :key="`bars${forecast.hour}${blocksPerDay}`")
								template(v-if="forecast.snow > 0")
									v-sheet.snow.bar(tile color="accent" :style="`height: ${forecast.snow / maxbar * 90}%;`")
									.bar.bg-ws-blue(:style="`height: ${forecast.snow / maxbar * 90}%;`")
								small.grey--text(v-else) -
						tr.bottom-border.compact
							th {{$t('page.areas._area.forecast:forecast_table.snow')}}
							td(:colspan="8/blocksPerDay" v-for="forecast in barforecasts")
								v-chip.px-1.white--text(color="accent" label x-small v-if="forecast.snow > 0") {{forecast.snow}}cm
								small.grey--text(v-else) -

		v-row
			v-col(cols="12" md="8" v-if="forecastBlog")
				h2
					nuxt-link(:to="localePath({name: 'weblog-slug', params: { 0: forecastBlog.slug}})" :title="forecastBlog.title") {{forecastBlog.title}}
				.subtitle-1.grey--text.mb-1 {{$t('page.weather:weatherreport.{date}.title', {date: $dayjs(forecastBlog.displayDate).format('dddd D MMMM')})}}
				nuxt-link(:to="localePath({name: 'weblog-slug', params: { 0: forecastBlog.slug}})" :title="forecastBlog.title")
					v-img(:src="`${forecastBlog.photo}?width=1024`" :aspect-ratio="16/9")
				v-row
					v-col.grow {{forecastBlog.content.introduction}}
						|
						|
						nuxt-link.text-no-wrap(:to="localePath({name: 'weblog-slug', params: { 0: forecastBlog.slug}})" :title="forecastBlog.title") » Weiterlesen
					v-col.shrink.hidden-sm-and-down
						img(:alt="$t('page.weather:roel.title')" width="128px" src="https://static.wintersport.nl/images/roel_trans.png" style="float: right;" loading="lazy")
						small.grey--text Wetterexperte Roel - Skiportal.de

				v-row
					v-col.grow
						client-only
							.mb-6
								h2 {{frontpageVideo.title}}
								div(style="position:relative")
									.cta-video {{frontpageVideo.subtitle}}
									vue-plyr.mb-3(v-if="frontpageVideo.video.videoSource === 'youtube' || frontpageVideo.video.videoSource === 'vimeo'" ref='plyr' :options='{"controls":["play", "progress", "volume", "play-large", "airplay", "fullscreen", "pip", "mute"], "disableContextMenu": false}' :key="frontpageVideo.video.videoId")
										div(:data-plyr-provider="frontpageVideo.video.videoSource" :data-plyr-embed-id='frontpageVideo.video.videoId')
									video-hls(v-else-if="frontpageVideo.video.videoSource === 'video'" :videoUrl="frontpageVideo.video.videoId" theme="nskiv" controls="")
								// .text-center
								// 	v-btn(outlined color="primary" :to="localePath({name:'videos'})") meer videos
			v-col(cols="12" md="4")
				v-card.mb-6(outlined)
					v-card-text
						h3 Dein Skiurlaub beginnt auf Skiportal.de
						v-img(src="https://cdn.wintersport.nl/vereniging/3/49012.jpg?width=380" :aspect-ratio="16/9" alt="Dein Skiurlaub beginnt auf Skiportal.de")
						p.mt-2 Willkommen auf Skiportal.de! Wir sind passionierte Skifahrer und Snowboarder und stellen euch auf unserer Webseite unser Wissen zur Verfügung. Wir helfen euch beim Aussuchen des idealen Skigebiets für euren Skiurlaub und wir verfügen über ein eigenes Wettermodell, dass speziell auf die Bedürfnisse von Skifahrern und Snowboarden zugeschnitten ist. Aktuelle Wettervorhersagen, Schneehöhen und Webcams zeigen euch auf, wie die Situation in den Alpen wirklich ist. Wir verstehen uns als unabhängiges Portal und -wo nötig- scheut sich unsere internationale Redaktion auch nicht davor, einen kritischen Blick auf das Thema Wintersport zu werfen. Mit Skiportal.de fährst du gut vorbereitet in die Alpen.
						div.font-weight-bold
							i Viel Spass im Skiurlaub!
						div
							i Dein Team von Skiportal.de

		h2.mt-4 Durchsuchen Sie die Skigebiete nach Themen
		v-row
			v-col(cols="6" sm="4" md="3" v-for="theme in themes" :key="theme.id")
				nuxt-link(:to="localePath({name: 'contentpage', params: { 0: theme.path.slice(1)}})" :title="theme.name")
					v-img(:src="`${theme.meta.image}?width=290`" :aspect-ratio="21/9" :alt="theme.name")
					| {{theme.name}}

		div.hidden-sm-and-down(slot="sidebar")
			portal-target(name="sidebar" multiple)

		portal(to="sidebar" :disabled="this.$vuetify.breakpoint.smAndDown" :order="1")
			snow-alarm.mb-6

		portal(to="sidebar" :disabled="this.$vuetify.breakpoint.smAndDown" :order="2")
			ad.mb-6(location="rotw")

		portal(to="sidebar" :disabled="this.$vuetify.breakpoint.smAndDown" :order="3")
			ad.mb-6(location="accofrontpage")

		portal(to="sidebar" :disabled="this.$vuetify.breakpoint.smAndDown" :order="4")
			ad.mb-6(location="frontpage")

</template>

<style scoped>
.frontpage-flag {
	width: 36px;
	vertical-align: baseline;
}
.forecast-table-wrapper .label {
	font-size: 80%;
	text-align: left;
	font-weight: bold;
}
</style>

<script>
import gql from 'graphql-tag';
import DisplayTemp from '@/components/display/temperature.vue';
import ForecastTable from '@/components/weather/forecast-table.vue';
import NavigationDefault from '@/components/navigation-default.vue';
import DestinationSelector from '@/components/common/destination-selector';
import Zeroline from '@/components/display/zeroline.vue';
import WeblogItem from '@/components/weblog-item';

const areaQuery = `
		area(id: $id) {
			id
			name
			slug
			maxElevation
			forecasts_daily: forecasts(interval: 24, limit: 144) {
				hour
				realdate
				sunScore
			}
			forecasts(interval: 24, limit: 144) {
				elevation
				hour
				realdate
				snow
				rain
				temperature
				windSpeed
				windDirection
				icon
			}
			zeroline: forecasts(interval: 3, limit: 144) {
				hour
				zeroline: zeroLine
			}
			resorts {
				nodes {
					id
					elevation
				}
			}
			snowheights {
				maxSnowMountain
				maxSnowValley
			}
		}
`;

const frontpageQuery = gql`
	query frontpage {
		frontpageItems {
			id
			title
			type
			image
			content
			url
			item {
				... on Weblog {
					...WeblogItem
				}
				... on Contentpage {
					id
					path
					title: name
					content {
						introduction(length: 120)
					}
					meta {
						image
					}
				}
				... on Topic {
					id
					title
					slug
					meta {
						image
					}
					displayDate: created_at
					replyCount
					author: profile {
						id
						slug
						username
					}
					content {
						introduction(length: 120)
					}
				}
				... on AcademyLesson {
					id
					title: name
					slug
					meta {
						image
					}
					content {
						introduction(length: 120)
					}
					chapter {
						id
						level {
							id
							slug
						}
					}
				}
				... on Area {
					id
					areaSlug: slug
					country {
						id
						name
						countryCode
					}
					maxElevation
					minElevation
					slopeinfo {
						total
					}
					meta {
						image
					}
				}
			}
		}
		frontpageVideo {
			id
			title
			subtitle
			video {
				videoId
				videoSource
			}
		}
		contentpage(id: "582a948e-2eba-4664-8695-64ade87b0b86") {
			id
			children {
				id
				name
				slug
				path
				meta {
					image
				}
			}
		}
		news: weblogsByCategory(limit: 8, category: "news") {
			weblogs {
				id
				slug
				title
				displayDate
				category
			}
		}
		weblogsByCategory(limit: 1, category: "weather") {
			weblogs {
				id
				title
				slug
				content {
					introduction(length: 240)
				}
				displayDate
				url
				photo
				replyCount
				category
				# author {
				# 	id
				# 	username
				# 	signature
				# 	teamDescription
				# 	role
				# }
				author: authorProfile {
					id
					username
					signature
					teamDescription
					# role
				}
			}
		}
	}
	${WeblogItem.fragment}
`;

const getAreaForecast = gql`
	query getAreaForecast($id: ID!) {
		${areaQuery}
	}
`;

export default {
	name: 'FrontpageDE',
	components: {
		NavigationDefault,
		ForecastTable,
		DisplayTemp,
		DestinationSelector,
		Zeroline,
		WeblogItem
	},
	data() {
		return {
			weblogsByCategory: {
				weblogs: []
			},
			news: {
				weblogs: []
			},
			frontpageItems: [],
			frontpageVideo: null,
			days: 6,
			blocksPerDay: 1,
			contentpage: { children: [] },
			forecastBlog: { content: {} },
			area: null,
			destinationForm: false,
			activeDestination: null,
			newDestination: null
		};
	},
	fetch() {
		return this.$graphClient
			.query({
				query: frontpageQuery,
				errorPolicy: 'all'
			})
			.then(({ data, errors }) => {
				if (errors) {
					throw errors[0];
				}

				this.frontpageItems = data.frontpageItems;
				this.frontpageVideo = data.frontpageVideo;
				this.contentpage = data.contentpage;
				this.forecastBlog = data.weblogsByCategory.weblogs[0];
				this.news = data.news;
			})
			.catch(error => {
				return console.error(error);
			});
	},
	computed: {
		forecastArea() {
			return this.activeDestination.__typename === 'Area'
				? this.activeDestination
				: this.activeDestination.area;
		},
		tableContentWidth() {
			return this.$vuetify.breakpoint.smAndDown
				? 860
				: this.$store.state.content_width.full - 100 - 48;
		},
		themes() {
			const themes = this.$lodash.cloneDeep(this.contentpage.children);
			return themes.slice(0, 8).map(theme => {
				theme.meta.image =
					theme.meta.image ||
					'https://static.wintersport.nl/images/ogimages/ws-wintersporters.jpg';

				return theme;
			});
		},
		mappedFrontpageItems() {
			return this.frontpageItems.map(item => {
				const itemCopy = this.$lodash.cloneDeep(item);

				if (itemCopy.type === 'custom') {
					itemCopy.item = itemCopy;
					if (itemCopy.image) itemCopy.item.photo = itemCopy.image;
				}

				itemCopy.item.title = itemCopy.title;

				if (itemCopy.type === 'topic') {
					itemCopy.item.photo = itemCopy.item.meta.image;
					itemCopy.path = {
						name: 'forum-topic',
						params: { slug: itemCopy.item.slug }
					};
				}
				if (itemCopy.type === 'weblog') {
					itemCopy.path = {
						name:
							itemCopy.item.category === 'news' ? 'news-slug' : 'weblog-slug',
						params: { 0: itemCopy.item.slug }
					};
				}
				if (itemCopy.type === 'contentpage') {
					itemCopy.item.photo = itemCopy.item.meta.image;
					itemCopy.path = {
						name: 'contentpage',
						params: { 0: itemCopy.item.path.slice(1) }
					};
				}
				if (itemCopy.type === 'academylesson') {
					itemCopy.item.photo = itemCopy.item.meta.image;
					itemCopy.path = {
						name: 'academy-level-lesson',
						params: {
							level: itemCopy.item.chapter.level.slug,
							lesson: itemCopy.item.slug
						}
					};
				}
				if (itemCopy.type === 'area') {
					itemCopy.item.photo = itemCopy.image;
					itemCopy.path = {
						name: 'areas-area',
						params: { area: itemCopy.item.areaSlug }
					};
				}

				itemCopy.item.photo =
					itemCopy.item.photo ||
					'https://static.wintersport.nl/images/ogimages/ws-wintersporters.jpg';

				return itemCopy;
			});
		},
		barforecasts() {
			return this.area.forecasts;
		},
		maxbar() {
			return [{ forecasts: this.area.forecasts }].reduce(carry => {
				return Math.max(carry);
			}, 0);
		},
		maxSnowValley() {
			return this.area.snowheights ? this.area.snowheights.maxSnowValley : 0;
		},
		maxSnowMountain() {
			return this.area.snowheights ? this.area.snowheights.maxSnowMountain : 0;
		},
		destinationLink() {
			if (this.activeDestination.__typename === 'Area') {
				return this.localePath({
					name: 'areas-area',
					params: { area: this.activeDestination.slug }
				});
			} else if (this.activeDestination.__typename === 'Resort') {
				return this.localePath({
					name: 'resorts-resort',
					params: { resort: this.activeDestination.slug }
				});
			} else {
				return null;
			}
		}
	},
	mounted() {
		if (this.$store.state.forecastDestination) {
			this.activeDestination = this.$lodash.cloneDeep(
				this.$store.state.forecastDestination
			);
		} else {
			this.activeDestination = {
				id: 'ed5a3dfc-017c-5ae6-9239-484701082866',
				__typename: 'Area',
				name: 'Stubaier Gletscher',
				slug: 'stubaier-gletscher'
			};
		}

		this.fetchForecast();
	},
	methods: {
		changeDestination() {
			this.activeDestination = this.newDestination;

			this.$store.commit('SET_FORECAST_DESTINATION', this.activeDestination);
			this.fetchForecast();

			this.newDestination = null;
			this.destinationForm = false;
		},
		fetchForecast() {
			this.$graphClient
				.query({
					query: getAreaForecast,
					variables: {
						id: this.forecastArea.id
					}
				})
				.then(({ data }) => {
					this.area = data.area;
				});
		}
	}
};
</script>
