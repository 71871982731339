<template lang="pug">
v-autocomplete(
	v-bind="$attrs" v-on="$listeners"
	item-text="name"
	item-value="id"
	v-model="destination"
	:items="entries"
	:search-input.sync="search"
	return-object
	)
	template(#item="data")
		v-list-item-avatar(size="20")
			ws-icon(small) {{icons[data.item.type]}}
		v-list-item-content {{data.item.name}}
</template>

<style lang="sass">
.searchbar
  // padding-right: 0 !important
  .v-autocomplete__content .v-list__item__avatar
    min-width: 35px
  // .v-input__append-inner
    margin-top: 0 !important
</style>

<script>
import gql from 'graphql-tag';

export default {
	props: {
		types: {
			type: Array,
			default() {
				return ['areas', 'resorts'];
			}
		},
		limit: {
			type: Number,
			default: 50
		}
	},
	data() {
		return {
			isLoading: false,
			destination: null,
			entries: [],
			search: '',
			icons: {
				Area: 'ski',
				Resort: 'village',
				Cluster: 'ski',
				Organisation: 'organisation',
				Skihall: 'skihall',
				Revolvingslope: 'revolvingslope',
				Dryslope: 'dryslope',
				Carpetslope: 'carpetslope'
			}
		};
	},
	watch: {
		search(val) {
			if (val == null || val === '') return false;
			this.isLoading = true;

			const searchQuery = gql`
				query search($val: String!, $types: [String]!, $limit: Int!) {
					search(term: $val, types: $types, limit: $limit) {
						type: __typename
						... on Area {
							id
							name
							slug
							geography {
								location {
									coordinates
								}
							}
						}
						... on Resort {
							id
							name
							slug
							area {
								id
							}
							geography {
								location {
									coordinates
								}
							}
						}
					}
				}
			`;

			this.$graphClient
				.query({
					query: searchQuery,
					variables: {
						val,
						types: this.types,
						limit: this.limit
					}
				})
				.then(res => res.data)
				.then(data => {
					this.entries = data.search;
				})
				.catch(_err => {})
				.finally(() => (this.isLoading = false));
		}
	}
};
</script>
