<template lang="pug">
nav
	.font-weight-bold.subtitle-1 {{$t('navigation-default:heading')}}
	nuxt-link.mb-2.mb-md-0(:to="localePath({name: 'areas'})") {{$tc('navigation-default:all.{count}.areas', 418)}}
	.font-weight-bold.subtitle-1.mt-3 {{$t('navigation-default:countries')}}
	ul.list-unstyled
		li.nav-list-item(v-for="(country, index) in visibleCountries")
			img.flag(:src="`https://static.wintersport.nl/images/flags/svg/${country.countryCode}.svg`", :alt="country.name" width="16")
			nuxt-link(:to="localePath({name: 'areas-countries-country', params: { country: country.slug} })") {{country.name}}
		li.nav-list-item(v-if="$store.state.countries.length > visibleCountries.length")
			a.never-visited.ws.ws-caret-down(href="#" @click.prevent="showAllCountries = true") {{$t('navigation-default:show.all.countries')}}

	.font-weight-bold.subtitle-1 {{$t('navigation-default:type.of.area')}}
	ul.list-unstyled
		li.nav-list-item
			nuxt-link(:to="localePath({name:'areas-theme-theme', params: {theme: $localeSlug('theme_childfriendly', $i18n.locale)}})") {{$t('navigation-default:childfriendly.areas')}}
		li.nav-list-item
			nuxt-link(:to="localePath({name:'areas-theme-theme', params: {theme: $localeSlug('theme_beginners', $i18n.locale)}})") {{$t('navigation-default:areas.for.beginners')}}
		li.nav-list-item
			nuxt-link(:to="localePath({name:'areas-theme-theme', params: {theme: $localeSlug('theme_advanced', $i18n.locale)}})") {{$t('navigation-default:areas.for.advanced')}}
		li.nav-list-item
			nuxt-link(:to="localePath({name:'areas-theme-theme', params: {theme: $localeSlug('theme_offpiste', $i18n.locale)}})") {{$t('navigation-default:off.piste')}}
		li.nav-list-item
			nuxt-link(:to="localePath({name:'areas-theme-theme', params: {theme: $localeSlug('theme_cheapareas', $i18n.locale)}})") {{$t('navigation-default:cheap.areas')}}
		li.nav-list-item
			nuxt-link(:to="localePath({name:'areas-theme-theme', params: {theme: $localeSlug('theme_tours', $i18n.locale)}})") {{$t('navigation-default:areas.for.tours')}}
		li.nav-list-item
			nuxt-link(:to="localePath({name:'areas-theme-theme', params: {theme: $localeSlug('theme_snowboardfriendly', $i18n.locale)}})") {{$t('navigation-default:areas.snowboard.friendly')}}
		li.nav-list-item
			nuxt-link(:to="localePath({name:'areas-theme-theme', params: {theme: $localeSlug('theme_quietareas', $i18n.locale)}})") {{$t('navigation-default:quiet.areas')}}
	.font-weight-bold.subtitle-1 {{$t('navigation-default:area.size')}}
	ul.list-unstyled
		li.nav-list-item
			nuxt-link(:to="localePath({name:'areas-theme-theme', params: {theme: $localeSlug('theme_big', $i18n.locale)}})") {{$t('navigation-default:big.areas')}}
		li.nav-list-item
			nuxt-link(:to="localePath({name:'areas-theme-theme', params: {theme: $localeSlug('theme_small', $i18n.locale)}})") {{$t('navigation-default:small.areas')}}
	.font-weight-bold.subtitle-1 Dorp
	ul.list-unstyled
		li.nav-list-item
			nuxt-link(:to="localePath({name:'areas-theme-theme', params: {theme: $localeSlug('theme_apresski', $i18n.locale)}})") {{$t('navigation-default:best.apres.ski')}}
		li.nav-list-item
			nuxt-link(:to="localePath({name:'areas-theme-theme', params: {theme: $localeSlug('theme_authentic', $i18n.locale)}})") {{$t('navigation-default:authentic.resorts')}}
		li.nav-list-item
			nuxt-link(:to="localePath({name:'areas-theme-theme', params: {theme: $localeSlug('theme_pisteinresort', $i18n.locale)}})") {{$t('navigation-default:pistes.in.resort')}}
	.font-weight-bold.subtitle-1 {{$t('navigation-default:snow.guarantee')}}
	ul.list-unstyled
		li.nav-list-item
			nuxt-link(:to="localePath({name:'areas-theme-theme', params: {theme: $localeSlug('theme_snowguaranteed', $i18n.locale)}})") {{$t('navigation-default:snowguaranteed.areas')}}
		li.nav-list-item
			nuxt-link(:to="localePath({name:'areas-theme-theme', params: {theme: $localeSlug('theme_summerski', $i18n.locale)}})") {{$t('navigation-default:summer.ski.areas')}}
	template(v-if="$i18n.locale !== 'de-DE'")
		.font-weight-bold.subtitle-1 {{$t('navigation-default:neighborhood')}}
		ul.list-unstyled
			li.nav-list-item
				nuxt-link(:to="localePath({name:'localdestinations-skihalls'})") {{$t('navigation-default:skihalls')}}
			li.nav-list-item
				nuxt-link(:to="localePath({name:'localdestinations-dryslopes'})") {{$t('navigation-default:dryslopes')}}
			li.nav-list-item
				nuxt-link(:to="localePath({name:'localdestinations-revolvingslopes'})") {{$t('navigation-default:revolvingslopes')}}
			li.nav-list-item
				nuxt-link(:to="localePath({name:'localdestinations'})") {{$t('navigation-default:all.options.in.the.area')}}
	navigation-default-list
	ad(location="navigation")
</template>

<script>
import NavigationDefaultList from '@/components/navigation-default-list';

export default {
	components: {
		NavigationDefaultList
	},
	data: () => ({
		showAllCountries: false
	}),
	computed: {
		visibleCountries() {
			return this.$store.state.countries.filter((country, index) => {
				return (
					index < 5 ||
					this.showAllCountries ||
					this.$store.state.countries.length < 7
				);
			});
		}
	}
};
</script>
