<template lang="pug">
div.mb-3(v-if="destination")
	v-divider.my-3
	nuxt-link.h2(:to="destinationLink('forecast')") {{destination.name}}

	template(v-if="snowheight")
		.caption
			span.success--text(v-if="snowheight.isResortOpen") ●
			span.error--text(v-else) ●
			|
			|
			span(v-if="snowheight.isResortOpen")
				strong {{$t('forecast.sidebar.label.open')}}
				|  ·
				|  {{$t('forecast.sidebar.label.pistes')}}
				strong.success--text  {{snowheight.slopeLengthOpen}}/{{snowheight.slopeLength}}
				| km ·
			strong(v-else) {{$t('forecast.sidebar.label.closed')}}
			|  ·
			span  {{$t('forecast.sidebar.label.snowheight-valley')}}
			strong.accent--text  {{snowheight.snowValley}}cm
			|  ·
			span  {{$t('forecast.sidebar.label.snowheight-mountain')}}
			strong.mr-1.accent--text  {{snowheight.snowMountain}}cm
			div(v-if="$store.state.user && daysUntilLeaving !== null && daysUntilLeaving > -1" v-html="$tc('forecast.sidebar.label.{count}_days', daysUntilLeaving)")
				//- | ·
			//- nuxt-link(:to="destinationLink('lesson')")  {{$t('forecast.sidebar.label.skiles')}}



	table.forecast-table-horizontal.mt-3(v-if="top.forecast")
		thead
			tr
				td
				th(v-for="forecast, index in top.forecast.forecast24.slice(0,6)" :key="`${forecast.realdate}-day`") {{$dayjs(forecast.realdate).format('dd')}}
		tbody
			tr
				td
				td(v-for="forecast, index in top.forecast.forecast24.slice(0,6)" :key="`${forecast.realdate}-icon`")
					img(:src="`https://static.wintersport.nl/images/weather/${forecast.icon}.svg`" width="25px")
			tr
				td.text-right.caption.pr-1
					.font-weight-bold Berg
					.grey--text(style="font-size:10px") {{top.forecast.measurepoint.elevation}}m
				td.relative(v-for="forecast, index in top.forecast.forecast24.slice(0,6)" :key="`${forecast.realdate}-toptemp`")
					display-temp(tag="span" showBold) {{forecast.temperature}}
			tr( v-if="low.forecast")
				th.text-right.caption.pr-1
					.font-weight-bold Dal
					.grey--text(style="font-size:10px") {{low.forecast.measurepoint.elevation}}m
				td(v-for="forecast, index in top.forecast.forecast24.slice(0,6)" :key="`${forecast.realdate}-lowtemp`")
					display-temp(tag="span" showBold) {{low.forecast.forecast24[index].temperature}}
			tr
				th.text-right.caption.pr-1.font-weight-bold Sneeuw
				td(v-for="forecast, index in top.forecast.forecast24.slice(0,6)" :key="`${forecast.realdate}-snow`")
					v-chip.px-1.accent--text(color="#4AA3DF26" label v-if="Math.round(forecast.snow) > 0") {{Math.round(forecast.snow)}}cm
					small.px-1.my-1.grey--text(v-else) -



	login-action(@on-click="destinationDialog = true")
		template(#activator="{ on }")
			.text-right
				a.caption.text-right(v-on="on") {{$t('forecast.sidebar.label.change-destination')}}

	validation-observer(v-slot="{ handleSubmit, invalid, dirty}")
		ws-dialog(v-model="destinationDialog" :dirty="dirty" :title="$t('forecast.sidebar:edit.destination.dialog.heading')")
			form(@submit.prevent="handleSubmit(changeDestination)")
				v-card-text
					.h3.mb-4 {{$t('forecast.sidebar.new.destination.dialog.title')}}
					validation-provider(v-slot="{errors, touched}" rules="required" :name="$t('forecast.sidebar:edit.destination.dialog.destination.input.error')" slim)
						destination-selector.mb-3(outlined :label="$t('forecast.sidebar:edit.destination.dialog.destination.input')" v-model="newDestination" hide-details="auto" :error-messages="touched ? errors : []")
					datepicker(outlined :label="`${$t('forecast.sidebar:edit.destination.dialog.destination.date')}`" v-model="newDestinationDate" hide-details="auto")

				v-card-actions
					v-spacer
					v-btn(outlined color="primary" @click="destinationDialog = false;") {{$t('forecast.sidebar:edit.destination.dialog.cancel')}}
					v-btn(color="primary" depressed type="submit" :disabled="invalid") {{$t('forecast.sidebar:edit.destination.dialog.submit')}}
</template>

<script>
import gql from 'graphql-tag';

import DisplayTemp from '@/components/display/temperature.vue';
import Datepicker from '@/components/common/datepicker';
import DestinationSelector from '@/components/common/destination-selector';

const forecastQuery = gql`
	query forecast($id: UUID!) {
		forecast(id: $id) {
			measurepoint {
				id
				name
				elevation
			}

			forecast24 {
				hour
				realdate
				rain
				snow
				temperature
				icon
				windDirection
				windSpeed
				sunScore
			}
		}
	}
`;

export default {
	components: {
		DisplayTemp,
		DestinationSelector,
		Datepicker
	},
	props: {},
	data() {
		return {
			low: {},
			top: {},
			destination: null,
			area: null,
			resort: null,
			snowheight: null,
			destinationDialog: false,
			newDestination: null,
			newDestinationDate: null
		};
	},
	computed: {
		daysUntilLeaving() {
			if (!this.$store.state.forecastDestination.date) {
				return null;
			}

			return this.$dayjs(this.$store.state.forecastDestination.date).diff(
				this.$dayjs().format('YYYY-MM-DD'),
				'day'
			);
		}
	},
	mounted() {
		this.fetchForecast();
	},
	methods: {
		async fetchForecast() {
			await this.$graphClient
				.query({
					query: gql`
						fragment AreaFields on Area {
							name
							slug
							denglMeasurepoints {
								id
							}
							snowheights {
								nodes {
									isResortOpen
									snowMountain
									snowValley
									slopeLength
									slopeLengthOpen
									elevationMountain: areaHeightMax
									elevationValley: areaHeightMin
								}
							}
						}

						query ($id: ID, $isResort: Boolean!) {
							resort(id: $id) @include(if: $isResort) {
								id
								name
								slug
								area {
									id
									...AreaFields
								}
								denglMeasurepoints {
									id
								}
							}

							area(id: $id) @skip(if: $isResort) {
								id
								...AreaFields
								mainResort {
									id
									name
									slug
									denglMeasurepoints {
										id
									}
								}
							}
						}
					`,
					variables: {
						id: this.$store.state.forecastDestination.id,
						isResort: this.$store.state.forecastDestination.type === 'Resort'
					}
				})
				.then(({ data }) => {
					if (this.$store.state.forecastDestination.type === 'Area') {
						this.destination = data.area;
						this.area = data.area;
						this.resort = data.area.mainResort;
					} else if (this.$store.state.forecastDestination.type === 'Resort') {
						this.destination = data.resort;
						this.resort = data.resort;
						this.area = data.resort.area;
					}
					this.snowheight = this.area.snowheights.nodes[0];
				})
				.catch(e => {
					console.error(e);
				});

			if (this.area) {
				await this.$graphClient
					.query({
						query: forecastQuery,
						variables: {
							id: this.area.denglMeasurepoints[0].id
						}
					})
					.then(({ data }) => {
						this.top = data;
					})
					.catch(e => {
						console.error(e);
					});
			}
			if (this.resort) {
				await this.$graphClient
					.query({
						query: forecastQuery,
						variables: {
							id: this.resort.denglMeasurepoints[0].id
						}
					})
					.then(({ data }) => {
						this.low = data;
					})
					.catch(e => {
						console.error(e);
					});
			}
		},
		destinationLink(path) {
			if (this.$store.state.forecastDestination.type === 'Area') {
				return this.localePath({
					name: `areas-area-${path}`,
					params: { area: this.area.slug }
				});
			} else if (this.$store.state.forecastDestination.type === 'Resort') {
				return this.localePath({
					name: `resorts-resort-${path}`,
					params: { resort: this.resort.slug }
				});
			}
		},
		async changeDestination() {
			await this.$store.commit('SET_FORECAST_DESTINATION', {
				id: this.newDestination.id,
				type: this.newDestination.type,
				date: this.newDestinationDate
			});

			await this.$graphClient
				.mutate({
					mutation: gql`
						mutation saveMyProfile($input: saveMyProfileInput!) {
							saveMyProfile(input: $input) {
								id
							}
						}
					`,
					variables: {
						input: {
							forecastDestination: {
								id: this.newDestination.id,
								type: this.newDestination.type,
								date: this.newDestinationDate
									? this.$dayjs(this.newDestinationDate)
									: null
							}
						}
					}
				})
				.then(({ _data, errors }) => {
					if (errors) {
						return this.$toast.error(
							this.$t('forecast.sidebar.error.while.saving')
						);
					}
					this.$toast.success(
						this.$t('forecast.sidebar.destination.change.success')
					);
				});

			this.newDestination = null;
			this.newDestinationDate = null;
			this.destinationDialog = false;

			this.fetchForecast();
		}
	}
};
</script>

<style lang="sass">

.forecast-table-horizontal
	width: 100%
	border-collapse: collapse
	table-layout: fixed

	th
		font-weight: bold
	td, th
		text-align: center
		padding-bottom: 0.5rem
		&:not(:first-child)
			border-left:  1px solid var(--v-border-base)
</style>
