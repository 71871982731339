<template lang="pug">
v-row
	v-col.shrink(cols="12" md="auto")
		optional-nuxt-link(:to="localePath({name:`${weblogPath}`, params: { 0: weblog.slug }, ...this.overridePath})")
			v-img(:aspect-ratio="16/9" :width="$vuetify.breakpoint.smAndUp ? 180: 'auto'" min-width="180" :class="{'play-btn': weblog.isVideoBlog}" :src="`${image}?width=320`" :alt="weblog.title")
	v-col.grow
		optional-nuxt-link.d-block.font-weight-bold.subtitle-1(:to="localePath({name: weblogPath, params: { 0: weblog.slug }, ...this.overridePath})") {{weblog.title}}
		v-row.caption.grey--text(dense)
			v-col.label.label-sm.shrink(v-if="weblog.label") {{weblog.label}}
			v-col.shrink.text-no-wrap
				nuxt-link(v-if="isNL && weblog.category !=='advertorial'" :to="localePath({name:'profile-slug', params: {slug: weblog.author.slug}})" :title="weblog.author.username") {{weblog.author.username}}
				span(v-else-if="$i18n.locale === 'de-DE'") {{weblog.author.username}}
				v-chip.px-1.mr-2.flex-grow-0.flex-shrink-0(label x-small color="grey lighten-2" v-if="weblog.category === 'advertorial'") gesponsord
			v-col.shrink.text-no-wrap(v-if="weblog.displayDate") {{$t('weblog-item:published.at.{date}', {date: $dayjs(weblog.displayDate).format('DD MMMM YYYY')})}}
			v-col.shrink.text-no-wrap(v-if="weblog.replyCount && weblog.isRepliesVisible")
				optional-nuxt-link.grey--text(:to="localePath({name: weblogPath, hash: '#reacties', params:{ 0: weblog.slug}, ...this.overridePath})") {{$tc('page.weblog.message:reply.{count}', weblog.replyCount, {count: weblog.replyCount})}}
		p.text--primary {{weblog.content.introduction}}
</template>

<script>
import gql from 'graphql-tag';

export default {
	props: {
		weblog: {
			type: Object,
			required: true
		},
		overridePath: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	computed: {
		image() {
			if (this.weblog.isVideoBlog && this.weblog.defaultVideo) {
				return this.weblog.defaultVideo.thumb;
			} else if (this.weblog.photo) {
				return this.weblog.photo;
			} else {
				return 'https://static.wintersport.nl/images/ogimages/ws-wintersporters.jpg';
			}
		},
		weblogPath() {
			return this.weblog.category === 'news' ? 'news-slug' : 'weblog-slug';
		},
		isNL() {
			return ['nl-NL', 'nl-BE'].includes(this.$i18n.locale);
		}
	},
	fragment: gql`
		fragment WeblogItem on Weblog {
			id
			slug
			title
			photo
			replyCount
			isReplyEnabled
			isRepliesVisible
			iso
			content {
				introduction(length: 120)
			}
			# author {
			# 	id
			# 	username
			# 	slug
			# }
			author: authorProfile {
				id
				username
				slug
			}
			displayDate
			isVideoBlog
			defaultVideo {
				thumb
			}
			category
		}
	`
};
</script>
